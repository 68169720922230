.project-info {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(242, 242, 242);
  /* background-color: rgb(235, 200, 197); */
  padding: 10px 50px;
  border-top: 0.5px solid gray
}
