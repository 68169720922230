.container {
  height: 150vh;
  position: relative;
  z-index: 1;
}

.sticky {
  position: sticky;
  top: 0px;
  height: 100vh;
  overflow: hidden;
}

.element {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image-container {
  position: relative;
}

.image {
  height: calc(100vh + 30px);
  object-fit: contain;
}
