.modal-vid {
  width: 60vw;
  aspect-ratio: 16 / 9;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.closeBtn {
  position: fixed;
  top: 10;
  left: 10;
  color: rgb(165, 165, 165);
  /* text-align: center;
  margin-top: 50px; */
  cursor: pointer;
}

.closeBtn:hover {
  opacity: 0.5;
}

.close-x {
  position: absolute;
  padding: 24px;
  cursor: pointer;
  font-size: 2rem;
  z-index: 20;
  transition: 0.5s ease;
}

.close-x:hover {
  color: rgb(95, 95, 95);
  transition: 0.5s ease;
}

.project-description {
  /* position: fixed;
  bottom: 0; */
}

.overlay {
  position: fixed;
  display: flex;
  flex-direction: column;
  /* justify-content: end; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: black; */
  background-color: rgb(234, 234, 234);
  border-left: 1px solid rgb(109, 109, 109);
  z-index: 10;
}

@media (max-width:1024px) {

  .modal-vid {
    width: 80vw;
  }

}

@media (max-width:600px) {

  .modal-vid {
    width: 95vw;
  }

}
