.project1 {
  position: absolute;
  top: 210vh;
  width: 100vw;
  /* height: 100vh; */
  /* padding-left: 50px; */
  background-color: rgb(234, 234, 234);
  color: rgb(18, 18, 18);
  z-index: 10;
  box-shadow: 0 -100px 50px rgba(0, 0, 0, 0.1);
}

.project-container {
  padding-top: 24px;
  text-align: left;
}

.project-title {
  border-top: 1px solid rgb(109, 109, 109);
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 115px;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 100px;
  transition: 0.5s ease;
  cursor: pointer;
  font-size: 2rem;
}

.project-year {
  padding-left: 50px;
  font-weight: 900;
  font-size: 1rem;
}

.link {
  text-decoration: none;
  font-weight: 500;
  transition: 0.5s ease;
  /* cursor: crosshair; */
  margin: 0;
}

.project-description {
  height: 100%;
  width: 250px;
  /* border-left: 1px solid rgb(109, 109, 109); */
  display: flex;
  align-items: center;
  padding-left: 12px;
  margin-left: auto;
}

/* .link:hover {
  opacity: 0.3;
  transition: 0.5s ease;
} */

.img-thumb {
  height: 100%;
  /* width: 200px; */
  aspect-ratio: 16 / 9;
  object-fit: cover;
  margin-left: auto;
  display: flex;
  z-index: 5;
  /* margin-right: 50px; */
}

.socials {
  position: fixed;
  display: flex;
  justify-content: space-between;
  gap: 24px;
  bottom: 24px;
  right: 24px;
  z-index: 5;
  mix-blend-mode: difference;
}

.socials a {
  color: rgb(126, 126, 126);
  text-decoration: none;
}

.socials a:hover {
  color: rgb(230, 230, 230);
  text-decoration: none;
}

@media (min-width:1024px) {

  .project-title:hover {
    height: 175px;
    background-color: rgb(229, 229, 229);
    /* color: rgb(78, 78, 78); */
    gap: 50px;
    /* font-size: 2.5rem; */
    transition: 0.5s ease;
    cursor: pointer;
  }

}

@media (max-width:1024px) {

  .project-title {
    border-top: 1px solid rgb(109, 109, 109);
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    height: 100px;
    gap: 75px;
  }

  .project-title:hover {
    height: 125px;
  }

  .link {
    font-size: 2rem;
  }

}

@media (max-width:600px) {

  .link {
    font-size: 1.5rem;
  }

  .socials {
    flex-direction: column;
    gap: 5px;
    text-align: right;
  }

}
