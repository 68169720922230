@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap');

.App {
  text-align: center;
  font-family: "Karla", sans-serif;
  background-color: black;
}

.text-block-container {
  margin: 0 auto;
  width: 950px;
}

.text-block {
  position: relative;
  top: -50vh;
  height: 120vh;
  color: white;
  background-color: black;
  font-size: 5rem;
  padding-bottom: 100px;
  text-align: center;
}

.footer {
  height: 100vh;
}

@media (max-width:1024px) {

  .text-block-container {
    margin: 0 auto;
    width: 600px;
  }

  .text-block {
    font-size: 4rem;
    /* padding: 0 100px; */
    height: auto;
  }

  .project1 {
    font-size: 1rem;
  }

  .link {
    font-size: 1rem;
  }

  .modal-vid {
    width: 80vw;
  }

}


@media (max-width:600px) {

  .text-block-container {
    margin: 0 auto;
    width: 350px;
    /* padding: 24px; */
  }

  .text-block {
    font-size: 3rem;
    /* padding: 0 24px; */
  }

}
